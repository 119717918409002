<template>
  <div style="width: 100%">
    <div class="d-flex align-content-center position-relative">
      <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
        <a
          href="#" 
          @click.prevent="disminuirCarrusel"
          class="arrow left"
        ></a>
      </div>
      <div class="flex-grow-1" style="width: 100%;">
        <CCollapse :show="carruselTab === 1"  class="p-arrows-carousel" >
          <CRow class="cardPersonalizado">
            <CCol md="12" lg="5" style="padding:0px;border-right: 1px solid #d8dbe0;">
              <div
                class="cardHeaderPersonalizado"
                :style="
                  'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                "
              >
                <span>
                  {{ $t("label.vesselInfo") }}
                </span>
              </div>
              <div style="width: 100%;">
                <InformationVessel 
                  :VesselInfoJson="VesselInfo"
                />
              </div>
            </CCol>
            <CCol md="12" lg="7" style="padding:0px">
              <VistaTransversal
                :toDeck="getToDeck"
                :toUnderDeck="getUnderDeck"
                :vesselType="VesselInfo?.TpVesselMasterId ?? ''"
                :TpVesselId="VesselInfo?.TpVesselId ?? ''"
                :positionJson="getPositionJson"
                fullscreen
                legend
                operationalSituation
              />
            </CCol>
          </CRow>
        </CCollapse>
        <CCollapse :show="carruselTab === 2"  class="p-arrows-carousel" >
          <div class="row">
            <div class="col-lg-3" v-for="(item, index) in simplePieArray" :key="index">
              <div
                class="card"
                :style="'border-color:'+item.colorCranes+'!important;height:500px;'"
              >
                <div class="card-header"
                  :style="
                    'background:' +
                    item.colorCranes +
                    '!important;width:100%;color:#FFFFFF;'
                  "
                >
                  <div class="d-flex">
                    <div class="flex-grow-1"><img class="img-fluid rounded" height="30px" width="30px" src="/img/gruasOperativa.svg" alt="Card image cap"> {{ item.titleSimplePie }}</div>
                    <div class="" @click.prevent="cargarLeyenda(item)" 
                      v-c-tooltip="{
                        content: $t('label.seeDatails'),
                        placement: 'top-end'
                      }"
                      style="cursor: pointer"><CIcon name="cil-info-circle"/></div>
                  </div>
                  
                </div>
                <div class="card-body" v-if="item.banderaSimple" style="padding-bottom:10px;">
                  <simpleDonutChart
                    width="100%"
                    height="410px"
                    :bandera="bandera(2)"
                    :title="item.titleSimplePie"
                    :series="item.seriesSimplePie"
                    :colors="item.ColorsArray"
                    :labels="item.labelsSimplePie"
                    textAlignLegend="start"
                    typeGraffic="pie"
                    positionLegend="top"
                  ></simpleDonutChart>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center" v-else style="height:55vh">
                  <h6 style="color:#F67272;text-align:center;" >{{$t('label.noDataAvailable')}}</h6>
                </div>
              </div>
            </div>
          </div>
        </CCollapse>
        <CCollapse :show="carruselTab === 3"  class="p-arrows-carousel" >
          <Movimientos 
            v-if="FgContainerShip" 
            :movimientos="getMovsData"
          />
          <MovementGeneralCargo 
            v-else-if="FgGeneralCargo" 
            :PlannedExecutedMovsJson="PlannedExecutedMovsJson"
          />
        </CCollapse>
        <CCollapse :show="carruselTab === 4"  class="p-arrows-carousel" v-if="FgGeneralCargo && DischargeJson.length!=0">
          <SituationPerPackagingDischarge 
            :DischargeJson="DischargeJson"
          />
        </CCollapse>
        <CCollapse :show="carruselTab === (DischargeJson.length!=0 ? 5 : 4)"  class="p-arrows-carousel" v-if="FgGeneralCargo && LoadJson.length!=0">
          <SituationPerPackagingLoad 
            :LoadJson="LoadJson"
          />
        </CCollapse>
        <CCollapse :show="carruselTab === (FgGeneralCargo ? (CarruselLimit == 7 ? 6 : CarruselLimit == 6 ? 5 : 4) : 4)"  class="p-arrows-carousel" >
          <TipoContenedores :TpCargo="OperSituationToday?.TpCargoJson" :CarruselLimit="CarruselLimit"/>
        </CCollapse>
        <CCollapse :show="carruselTab === (CarruselLimit == 7 ? 7 : CarruselLimit == 6 ? 6 : 5)"  class="p-arrows-carousel" v-if="FgGeneralCargo">
          <Consignees
            :ConsigneeJson="ConsigneeJson"
          />
        </CCollapse>
      </div>
      <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
        <a
          href="#" 
          @click.prevent="aumentarCarrusel"
          class="arrow right"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import simpleDonutChart from "../../components/simpleDonutChart";
import InformationVessel from "./InformationVessel";
import TipoContenedores from "./TipoContenedores";
import VistaTransversal from "../../components/vista-transversal";
import Movimientos from "./movimientos";
import MovementGeneralCargo from "./movement-general-cargo";
import SituationPerPackagingDischarge from "./situation-per-packaging-discharge";
import SituationPerPackagingLoad from "./situation-per-packaging-load";
import Consignees from "./consignees";
import { mapState } from 'vuex';
//data
function data() {
  return {
    interval: null,
  }
}
//methods
function bandera(item){
  return this.$store.state.situacionOperativaActual.tabsOperationes === 1 && this.$store.state.situacionOperativaActual.carruselTab === item;
}
function collapseShow(item){
  return this.$store.state.situacionOperativaActual.carruselTab === item;
}
function disminuirCarrusel(){
  if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
  }
}
function aumentarCarrusel(){
  if(this.$store.state.situacionOperativaActual.carruselTab !== this.CarruselLimit){
    this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
  }
}
function cargarLeyenda(item){
  let data = [];
  for (let index = 0; index < item.labelsSimplePie.length; index++) {
    data.push({
      label: item.labelsSimplePie[index] + ': ' + item.seriesSimplePie[index],
      color: item.ColorsArray[index]
    })
  }
  this.$store.commit('situacionOperativaActual/asignarModalInformacion', {
    title: item.titleSimplePie,
    data: data,
    iconShow: false,
    nameIcon: "",
    show:true,
  })
}
//computed
function getPositionJson() {
  let PositionJson = this.VesselInfo?.PositionJson ?? [];
  return PositionJson.map((item) => {
    return {
      ...item,
      Position: item.Position,
      PositionJson: item.PositionJson,
      FgBay: item.FgBay,
      FgCrane: item.FgCrane,
      FgHold: item.FgHold
    }
  });
}
function minCarrusel(){
  return this.$store.state.situacionOperativaActual.carruselTab > 1;
}
function maxCarrusel(){
  return this.$store.state.situacionOperativaActual.carruselTab < this.CarruselLimit;
}
function simplePieArray() {
  let data = [];
  let DataCranes = this.OperSituationToday?.CranesJson ? this.OperSituationToday?.CranesJson : [];
  let theValueIs = this.$t('label.lang');
  DataCranes.map(async (item) => {
    let labels = theValueIs == "ESPAÑOL" ? item.LabelEs : item.LabelEn;
    if(labels.length !== 0 && item.Series.length !== 0){
      let array = item.Series;
      let banderaSimple = false;
      for (let index = 0; index < array.length; index++) {
        if(array[index] > 0){
          banderaSimple = true;
        }
      }
      data.push({
        titleSimplePie: item.CraneAlias,
        ColorsArray: item.ColorsArray,
        colorCranes: item.Color,
        banderaSimple: banderaSimple,
        seriesSimplePie: item.Series,
        labelsSimplePie: labels,
      });
    }
  });
  return data;
}
function getMovsData() {
  if(Object.keys(this.OperSituationToday).length !== 0){
    return {
      PlannedMovsJson: this.OperSituationToday.PlannedMovsJson,
      ExecutedMovsJson: this.OperSituationToday.ExecutedMovsJson,
      TodayMovsJson: this.OperSituationToday.TodayMovsJson,
    };
  }else{
    return {}
  }
}

function PlannedExecutedMovsJson() {
  return this.OperSituationToday?.PlannedExecutedMovsJson ?? [];
}

function DischargeJson() {
  let PlannedExecutedMovsByStowageEventJson = this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson && this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson[0] ? 
    this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson[0] : {};
  return PlannedExecutedMovsByStowageEventJson?.DischargeJson ? PlannedExecutedMovsByStowageEventJson.DischargeJson  : [];
}

function LoadJson() {
  let PlannedExecutedMovsByStowageEventJson = this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson && this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson[0] ? 
    this.OperSituationToday?.PlannedExecutedMovsByStowageEventJson[0] : {};
  return PlannedExecutedMovsByStowageEventJson?.LoadJson ? PlannedExecutedMovsByStowageEventJson.LoadJson  : [];
}

function ConsigneeJson() {
  return this.OperSituationToday?.ConsigneeJson ?? [];
}

function BlJson() {
  return this.OperSituationToday?.BlJson ?? [];
}

function getToDeck() {
  return this.$store.state.situacionOperativaActual.transversalView.toDeck;
}
function getUnderDeck() {
  return this.$store.state.situacionOperativaActual.transversalView.underDeck;
}

function BulkVesselId() {
  return process.env.VUE_APP_BULK_VESSEL_ID;
}

function CarruselLimit() {
  if (this.FgGeneralCargo) {
    let Limit = 5
    if (this.DischargeJson.length != 0) {
      Limit++;
    }
    if (this.LoadJson.length != 0) {
      Limit++;
    }
    return Limit;
  } else {
    return 4
  }
}

export default {
  name: "OperationalSituation",
  data,
  props: {
    Vessel: {
      type: Object,
      default: function () {
        return {};
      },
    },
    VesselInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    OperSituationToday: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    simpleDonutChart,
    TipoContenedores,
    Movimientos,
    MovementGeneralCargo,
    VistaTransversal,
    InformationVessel,
    SituationPerPackagingDischarge,
    SituationPerPackagingLoad,
    Consignees,
  },
  methods:{
    collapseShow,
    disminuirCarrusel,
    aumentarCarrusel,
    cargarLeyenda,
    bandera,
    _setInterval: function() {
      this.interval = setInterval(() => {
        if(this.$store.state.situacionOperativaActual.carruselTab !== 4){
          this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }else{
          this.$store.state.situacionOperativaActual.carruselTab = 1;
        }   
      }, 60000);
    }
  },
  beforeDestroy(){
    clearInterval(this.interval);
    this.interval = null;
  },
  computed: {
    simplePieArray,
    getMovsData,
    PlannedExecutedMovsJson,
    DischargeJson,
    LoadJson,
    ConsigneeJson,
    BlJson,
    getToDeck,
    getUnderDeck,
    minCarrusel,
    maxCarrusel,
    getPositionJson,
    BulkVesselId,
    CarruselLimit,
    ...mapState({
      carruselTab : state=> state.situacionOperativaActual.carruselTab,
      tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
      carrusel: state => state.situacionOperativaActual.carrusel,
      FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
      FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
    })
  },
  watch:{
    tabsOperationes(newValue,OldValue){
      if(OldValue === 1 && this.FgContainerShip){
        this.$store.state.situacionOperativaActual.carruselTab = 1;
        clearInterval(this.interval);
        this.interval = null;
      }else if(newValue === 1 && this.FgContainerShip){
        this.$nextTick(async () => {
          if(this.carrusel){
            this._setInterval();
          }
        });
      }
    },
    carrusel(newValue){
      if(this.tabsOperationes === 1 && this.FgContainerShip){
        if(newValue){
          this.$nextTick(async () => {
            this._setInterval();
          });
        }else{
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }
  }
};
</script>