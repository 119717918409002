<template>
  <div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
      <symbol id="icon-vehiculo" fill="currentColor" viewBox="0 0 28.93 28.93">
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path d="M25.34,13.56v2.82c0,.17-.05.28-.15.31l-.06,0v0l-.09,0H25l-.17,0v3.25l.23,0a.39.39,0,0,1,.32.38v3a1.39,1.39,0,0,1-.28.8,14.41,14.41,0,0,0-3-22l3.18,11A1.5,1.5,0,0,1,25.34,13.56Z"/>
            <path d="M7.85,24.26a2,2,0,0,0,1.94-2,1.82,1.82,0,0,0,0-.32l0-.23H2a15.24,15.24,0,0,0,1.86,2.51Z"/>
            <path d="M21.7,24.55a2.9,2.9,0,0,0-4.89,0,2.86,2.86,0,0,0-.47,1.57,2.91,2.91,0,0,0,1.33,2.44,14.41,14.41,0,0,0,4.43-1.83,3.08,3.08,0,0,0,.07-.61A2.94,2.94,0,0,0,21.7,24.55Z"/>
            <polygon points="15.84 4.34 15.84 11.24 21.16 11.24 20.02 4.34 15.84 4.34"/>
            <path d="M15.56,26.12a3.42,3.42,0,0,1,.13-.93l.09-.35H14.47a1.4,1.4,0,0,1-1.4-1.4V2.4A1.4,1.4,0,0,1,14.47,1h5.26a14.17,14.17,0,0,0-5.27-1A14.44,14.44,0,0,0,1.56,21H10a.38.38,0,0,1,.35.23,2.56,2.56,0,0,1,.24,1.1A2.72,2.72,0,0,1,7.85,25H4.62a14.37,14.37,0,0,0,9.84,3.9,14.51,14.51,0,0,0,2.2-.19A3.65,3.65,0,0,1,15.56,26.12Z"/>
            <path d="M22.72,24.84l.1.35a3.86,3.86,0,0,1,.13.93s0,0,0,0a13.08,13.08,0,0,0,1.91-1.65,1.38,1.38,0,0,1-.87.33Z"/>
            <path d="M24.36,20.87a.4.4,0,0,1-.32-.39V16.39a.4.4,0,0,1,.31-.39l.22,0V13.58L21.31,2.23a.63.63,0,0,0-.59-.45H14.47a.62.62,0,0,0-.62.62v21a.62.62,0,0,0,.62.62h1.32l.14,0,.2.09.13-.18A3.64,3.64,0,0,1,22.21,24l.08.14,1.69,0a.6.6,0,0,0,.44-.18h0a.58.58,0,0,0,.18-.43V20.91Zm-7.69-6.59H15.28v-.83h1.39ZM15.11,12V3.61h5.52l0,.11L22,12Z"/>
          </g>
        </g>
      </symbol>
    </svg>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
      <symbol id="icon-gamela" fill="currentColor" viewBox="0 0 28.93 28.93">
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path d="M8.67,19.06H22.8a1.89,1.89,0,0,0,1.81-1.95V4.85A2,2,0,0,0,24.42,4a15.35,15.35,0,0,0-1.23-1,1.6,1.6,0,0,0-.39-.05H5.8A14.41,14.41,0,0,0,3.05,5.61L7,17.83A1.81,1.81,0,0,0,8.67,19.06ZM6.15,8.14a.37.37,0,0,1,.37-.37H11v-1a.37.37,0,0,1,.37-.37.36.36,0,0,1,.37.37v3.73h2.8V6.74a.36.36,0,0,1,.37-.37.37.37,0,0,1,.37.37v3.73h2.3V6.74a.37.37,0,0,1,.37-.37.36.36,0,0,1,.37.37v3.73h2.8V6.74a.37.37,0,1,1,.74,0v8.88a.37.37,0,0,1-.74,0v-4.4H18.3v4.4a.36.36,0,0,1-.37.37.37.37,0,0,1-.37-.37v-4.4h-2.3v4.4a.37.37,0,0,1-.37.37.36.36,0,0,1-.37-.37v-4.4h-2.8v4.4a.36.36,0,0,1-.37.37.37.37,0,0,1-.37-.37V14H8.48a.37.37,0,0,1-.37-.37.38.38,0,0,1,.37-.37H11V8.51H6.52A.36.36,0,0,1,6.15,8.14Z"/>
            <path d="M14.46,0A14.41,14.41,0,0,0,6.88,2.16H22A14.36,14.36,0,0,0,14.46,0Z"/>
            <path d="M8.08,25.28l.08-.34H7.49a2.73,2.73,0,0,1-2.71-2.73A2.9,2.9,0,0,1,5,21.12a.39.39,0,0,1,.37-.24h22A14.35,14.35,0,0,0,25.35,5V17.11A2.63,2.63,0,0,1,22.8,19.8H8.67A2.53,2.53,0,0,1,6.3,18.11L2.5,6.33A14.45,14.45,0,0,0,8.33,27.54,3.53,3.53,0,0,1,8,26,3.39,3.39,0,0,1,8.08,25.28Z"/>
            <path d="M27,21.65H5.63l0,.23a1.84,1.84,0,0,0,0,.33,2,2,0,0,0,1.93,1.95h1L8.61,24a3.63,3.63,0,0,1,6.15,0l.08.13H25.17A14.15,14.15,0,0,0,27,21.65Z"/><path d="M15.27,25.28a3.07,3.07,0,0,1,.1.74A3.68,3.68,0,0,1,14,28.9l.49,0a14.46,14.46,0,0,0,10-4H15.2Z"/>
            <path d="M11.69,23.11A3,3,0,0,0,8.77,26a2.9,2.9,0,0,0,.85,2.06,15.12,15.12,0,0,0,3,.71,2.92,2.92,0,0,0,2-2.77,3,3,0,0,0-2.91-2.91Z"/>
          </g>
        </g>
      </symbol>
    </svg>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
      <symbol id="icon-sol" fill="currentColor" viewBox="0 0 44 44">
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path class="cls-1" d="M22,7.5A1.45,1.45,0,0,1,20.5,6V1.5A1.49,1.49,0,0,1,20.92.42a1.6,1.6,0,0,1,2.16,0A1.49,1.49,0,0,1,23.5,1.5V6A1.45,1.45,0,0,1,22,7.5Zm10.25,4.25a1.45,1.45,0,0,1,0-2.1l3.15-3.2a1.51,1.51,0,0,1,2.15,0,1.45,1.45,0,0,1,0,2.1l-3.2,3.2a1.45,1.45,0,0,1-2.1,0ZM38,23.5a1.5,1.5,0,1,1,0-3h4.5a1.5,1.5,0,1,1,0,3ZM22,44a1.44,1.44,0,0,1-1.5-1.5V38a1.5,1.5,0,1,1,3,0v4.5A1.44,1.44,0,0,1,22,44ZM9.65,11.75,6.45,8.6a1.51,1.51,0,0,1,0-2.15,1.45,1.45,0,0,1,2.1,0l3.2,3.2a1.45,1.45,0,0,1,0,2.1,1.55,1.55,0,0,1-1.07.4A1.39,1.39,0,0,1,9.65,11.75Zm25.8,25.8-3.2-3.2a1.45,1.45,0,0,1,0-2.1,1.38,1.38,0,0,1,1-.4,1.55,1.55,0,0,1,1.08.4L37.6,35.4A1.37,1.37,0,0,1,38,36.48a1.61,1.61,0,0,1-.42,1.07,1.51,1.51,0,0,1-2.15,0ZM1.5,23.5a1.49,1.49,0,0,1-1.08-.42,1.6,1.6,0,0,1,0-2.16A1.49,1.49,0,0,1,1.5,20.5H6a1.5,1.5,0,1,1,0,3Zm5,14a1.45,1.45,0,0,1,0-2.1l3.2-3.2a1.38,1.38,0,0,1,1-.4,1.55,1.55,0,0,1,1.08.4,1.51,1.51,0,0,1,0,2.15L8.6,37.55a1.51,1.51,0,0,1-2.15,0ZM22,34A12,12,0,0,1,10,22,12,12,0,0,1,22,10,12,12,0,0,1,34,22,12,12,0,0,1,22,34Zm0-3a8.95,8.95,0,0,0,9-9,9,9,0,0,0-9-9,9,9,0,0,0-9,9,9,9,0,0,0,9,9Z"/>
          </g>
        </g>
      </symbol>
    </svg>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
      <symbol id="icon-luna" fill="currentColor" viewBox="0 0 35.89 39.76">
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path class="cls-1" d="M20,36.76a16.3,16.3,0,0,0,5.8-1.15,20.26,20.26,0,0,0,5.7-3.4,27.81,27.81,0,0,1-8.45-4.58A22.37,22.37,0,0,1,17.42,21a23.18,23.18,0,0,1-2.65-8.25,27.42,27.42,0,0,1,.48-9.32,19.6,19.6,0,0,0-8.9,6.32A15.8,15.8,0,0,0,3,19.76a16.43,16.43,0,0,0,4.9,12.1A16.47,16.47,0,0,0,20,36.76Zm0,3a19.84,19.84,0,0,1-20-20A19.4,19.4,0,0,1,4.73,6.93,19.36,19.36,0,0,1,16.5.06a1.73,1.73,0,0,1,1.9.57,2.32,2.32,0,0,1,.15,2.18,21.15,21.15,0,0,0-.9,8.65,21.3,21.3,0,0,0,2.5,8.15,20.72,20.72,0,0,0,5.57,6.47A18.74,18.74,0,0,0,34,29.66a2.17,2.17,0,0,1,1.78,1.1,1.71,1.71,0,0,1-.48,1.85,22.92,22.92,0,0,1-6.9,5.15A18.79,18.79,0,0,1,20,39.76Z"/>
          </g>
        </g>
      </symbol>
    </svg>
    <CRow class="cardPersonalizado">
      <CCol md="12" lg="6" style="padding:0px;border-right: 1px solid #d8dbe0;">
        <div
          class="cardHeaderPersonalizado d-flex"
          :style="
            'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
          "
        >
          <div class="flex-fill d-flex align-items-center">
            <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos1"/></svg> 
            <span style="margin-left:5px;">
              {{ $t("label.SituacionBuque") }}
            </span>
          </div>
          <div class="flex-fill d-flex">
            <div style="margin-right:10px;">
              <h6 style="margin-bottom: 0px;font-size: 0.75rem;">ATA</h6>
              <b>{{ operationInfo.ata }}</b>
            </div>
            <div>
              <h6 style="margin-bottom: 0px;font-size: 0.75rem;">ETD</h6>
              <b>{{ operationInfo.etd }}</b>
            </div>
          </div>
        </div>
        <div style="width: 100%;padding:10px;">
          <div class="d-flex flex-row" style="background: #F5F5F5;padding:5px;border-radius: 5px;min-height: 65px;">
            <CRow>
              <CCol md="12">
                <CRow>
                  <div class="p-2 pl-4" style='box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.2);'>
                    <h6 style="margin-bottom: 0px;">{{ $t('label.IniOps') }}</h6>
                    <b style="margin-top:3px;">{{ operationInfo.ini }}</b>
                  </div>
                  <div class="p-2" style='box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.2);'>
                    <h6 style="margin-bottom: 0px;">{{ $t('label.FinOps') }}</h6>
                    <b style="margin-top:3px;">{{ operationInfo.end }}</b>
                  </div>
                  <div class="p-2">
                    <h6 style="margin-bottom: 0px;">{{ $t('label.HrOperando') }}</h6>
                    <b style="margin-top:3px;">{{ operationInfo.total }}</b>
                  </div>
                </CRow>
              </CCol>


                <CCol class="flex-fill" style="margin-top: 10px; width: 33%;">
                  <CRow>
                    <CCol md="6" class="pr-0">
                      <CCol md="12" style="text-align:center; padding-right: 8px; padding-left: 8px; border-radius: 10px; background: #ffffff">
                        <label style="color:#1A3760;margin-top:10px;font-size: 0.6rem;font-weight: bold;">{{ $t('label.Loadeds') }}</label>
                        <div style="height: 100px;text-align: center;">
                          <div style="margin-top:15px;">
                            <svg class="bi flex-shrink-0 me-2" width="50" height="50" role="img" aria-label="watch:" style="color:#db8f5c"><use xlink:href="#icon-gamela"/></svg>
                          </div>
                          <div>
                            <div style="width: 100%;color:#1A3760;padding:2px;text-align: center;font-size:1.6rem;margin-top:10px;font-weight: bold;">
                              <b>{{ operationGamelas.ImplementEmbarked }}</b>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CCol>
                    <CCol md="6" class="pr-0">
                      <CCol md="12" style="text-align:center;padding-right: 8px;padding-left: 8px; border-radius: 10px; background: #ffffff">
                        <label style="color:#1A3760;margin-top:10px;font-size: 0.6rem;font-weight: bold;">{{ $t('label.Returned') }}</label>
                        <div style="height: 100px;text-align: center;">
                          <div style="margin-top:15px;">
                            <svg class="bi flex-shrink-0 me-2" width="50" height="50" role="img" aria-label="watch:" style="color:#859dba"><use xlink:href="#icon-gamela"/></svg>
                          </div>
                          <div>
                            <div style="width: 100%;color:#1A3760;padding:2px;text-align: center;font-size:1.6rem;margin-top:10px;font-weight: bold;">
                              <b>{{ operationGamelas.ImplementReturned }}</b>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CCol>

                    <CCol md="6" class="pr-0">
                      <CCol md="12" style="margin-top: 10px; text-align:center;padding-right: 8px;padding-left: 8px; border-radius: 10px; background: #ffffff">
                        <label style="color:#1A3760;margin-top:10px;font-size: 0.6rem;font-weight: bold;">{{ $t('label.InCircuit') }}</label>
                        <div style="height: 100px;text-align: center;">
                          <div style="margin-top:15px;">
                            <svg class="bi flex-shrink-0 me-2" width="50" height="50" role="img" aria-label="watch:" style="color:#1b3a5f"><use xlink:href="#icon-vehiculo"/></svg>
                          </div>
                          <div>
                            <div style="width: 100%;color:#1A3760;padding:2px;text-align: center;font-size:1.6rem;margin-top:10px;font-weight: bold;">
                              <b>{{ operationGamelas.VehiclesInCircuit }}</b>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CCol>
                    
                    <CCol md="6" class="pr-0">
                      <CCol md="12" style="margin-top: 10px; text-align:center;padding-right: 8px;padding-left: 8px; border-radius: 10px; background: #ffffff">
                        <label style="color:#1A3760;margin-top:10px;font-size: 0.59rem;font-weight: bold;">{{ $t('label.OrderIssued') }}</label>
                        <div style="height: 100px;text-align: center;">
                          <div style="margin-top:15px;">
                            <svg class="bi flex-shrink-0 me-2" width="50" height="50" role="img" aria-label="watch:" style="color:#e47e3b"><use xlink:href="#icon-gamela"/></svg>
                          </div>
                          <div>
                            <div style="width: 100%;color:#1A3760;padding:2px;text-align: center;font-size:1.6rem;margin-top:10px;font-weight: bold;">
                              <b>{{ operationGamelas.ImplementDispatched }}</b>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CCol>
                  </CRow>
                </CCol>
                
                <CCol class="flex-fill pr-0" style="margin-top:10px; min-height: 165px; width: 33%;">
                  <div class="flex-fill" style="text-align:center;">
                    <div class="contenedorImagen" style="height: 305px;width:100%; border-radius: 10px;">
                      <div style="height: 76px;width:100%;display: flex;flex-direction: column;justify-content: stretch;">
                        <div class="centrado col-lg-12" style="margin-top:10px;">
                          <b>
                            <h6 class="mt-0" style="font-weight: bold;margin-bottom: 0px;font-size: 0.7rem; text-align:left;">{{ 'TON '+$t('label.PerHours') }}</h6>
                            <h2 style="margin-bottom: 0px;font-size: 1.9rem; text-align:center;"><b>{{ valuemoney(operationGamelas.TonXHour) }}</b></h2>
                          </b>
                        </div>

                        <div class="centrado col-lg-12">
                          <b>
                            <h6 style="font-weight: bold;margin-bottom: 0px;font-size: 0.7rem; text-align:left;">{{ 'TON '+$t('label.PerGamela') }}</h6>
                            <h2 style="margin-bottom: 0px;font-size: 1.9rem; text-align:center;"><b>{{ valuemoney(operationGamelas.TonPromXImplement) }}</b></h2>
                          </b>
                        </div>
                        <div class="centrado col-lg-12">
                          <b>
                            <h6 style="font-weight: bold;margin-bottom: 0px;font-size: 0.7rem; text-align:left;">{{ 'TON '+$t('label.PerDay') }}</h6>
                            <h2 style="margin-bottom: 0px;font-size: 1.9rem; text-align:center;"><b>{{ valuemoney(HoldSituationJson.TonXDay) }}</b></h2>
                          </b>
                        </div>
                        <div class="centrado col-lg-12">
                          <b>
                            <h6 style="font-weight: bold;margin-bottom: 0px;font-size: 0.7rem; text-align:left;">{{ 'TON '+$t('label.Loadeds') }}</h6>
                            <h2 style="padding:2px;margin-bottom: 0px;font-size: 1.9rem; text-align:center;"><b>{{ valuemoney(HoldSituationJson.TonEmbarked) }}</b></h2>
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>

                <CCol class="flex-fill Space-Evenly" style="margin-top:10px; width: 33%;">
                      <h6 style="margin-bottom: 0px;font-size: 0.7rem;">{{ $t('label.CTotalVoyages') }}</h6>
                      <b style="font-weight: bold;">{{ operationGamelas.TotalVoyages }}</b>

                      <h6 style="margin-bottom: 0px;margin-top:10px;font-size: 0.7rem;">{{ $t('label.TonPlanning') }}</h6>
                      <b style="font-weight: bold;">{{ valuemoney(operationGamelas.TonPlanif) }}</b>

                      <h6 style="margin-bottom: 0px;margin-top:10px;font-size: 0.7rem;">{{ $t('label.TonRemai') }}</h6>
                      <b style="font-weight: bold;">{{ valuemoney(operationGamelas.TonRemaining) }}</b>

                      <h6 style="margin-bottom: 0px;margin-top:10px;font-size: 0.7rem;">{{ $t('label.GamelaXdia') }}</h6>
                      <b style="font-weight: bold;">{{ valuemoney(operationGamelas.CantImplementPerDay) }}</b>

                      <h6 style="margin-bottom: 0px;margin-top:10px;font-size: 0.7rem;">TON. {{ $t('label.LastReading') }}</h6>
                      <b style="font-weight: bold;">{{ valuemoney(operationGamelas.LastRead) }}</b>

                      <h6 style="margin-bottom: 0px;margin-top:10px;font-size: 0.7rem;">{{ $t('label.DateLastRead') }}</h6>
                      <b style="font-weight: bold;">{{ operationGamelas.LastReadDateModificada }}</b>
                </CCol>
            </CRow>
          </div>
        </div>
      </CCol>
      <CCol md="12" lg="6" style="padding:0px;border-right: 1px solid #d8dbe0;">
        <div
          class="cardHeaderPersonalizado d-flex align-items-center"
          :style="
            'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
          "
        >
          <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> 
          <span style="margin-left:5px;">
            {{ $t("label.SituacionBodega") }}
          </span>
        </div>
        <div style="width: 100%;">
          <div style="width: 100%;padding:10px;">
            <div class="d-flex flex-row" style="background: #edebeb;padding:5px;border-radius: 5px;min-height: 65px;">
              <div class="p-2" style='box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.2);'>
                <h6 style="margin-bottom: 0px;">{{ $t('label.CargoShipped') }}: TOTAL <strong>{{ valuemoney(EmbarkedCargo.TotalTon) }}</strong></h6>
                <div class="d-flex d-flex align-item-center" style="margin-top:3px">
                  <div class="flex-fill">
                    <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="watch:"><use xlink:href="#icon-sol"/></svg> 
                  </div>
                  <div class="flex-fill">
                    <b style="font-weight: bold;">{{ valuemoney(EmbarkedCargo.DayTon) }} TON</b>
                  </div>
                  <div class="flex-fill">
                    <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="watch:"><use xlink:href="#icon-luna"/></svg> 
                  </div>
                  <div class="flex-fill">
                    <b style="font-weight: bold;">{{ valuemoney(EmbarkedCargo.NightTon) }} TON</b>
                  </div>
                </div>
              </div>
              <div class="p-2">
                <h6 style="margin-bottom: 0px;">{{ $t('label.movements') }}: TOTAL <strong>{{ Movements.TotalMov }}</strong></h6>
                <div class="d-flex d-flex align-item-center" style="margin-top:3px">
                  <div class="flex-fill">
                    <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="watch:"><use xlink:href="#icon-sol"/></svg> 
                  </div>
                  <div class="flex-fill">
                    <b style="font-weight: bold;">{{ Movements.DayMov }} MOVS</b>
                  </div>
                  <div class="flex-fill">
                    <svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="watch:"><use xlink:href="#icon-luna"/></svg> 
                  </div>
                  <div class="flex-fill">
                    <b style="font-weight: bold;">{{ Movements.NightMov }} MOVS</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex" style="margin-top:10px;">
              <div class="flex-fill">
                <div class="row">
                  <div class="col-sm-6">
                    <div style="background: #F6E2D7;padding:15px;border-radius: 5px;">
                      <h6 style="font-size: 0.7rem;font-weight: bold;">TON / 24 {{ $t('label.hour')+'S' }}</h6>
                      <div class="row" style="height: 130px;">
                        <div class="col" style="text-align:center;padding-right: 8px;padding-left: 8px;" v-for="(item, index) in DataTonByHold24Hrs" :key="index">
                          <div class="w-100 d-flex justify-content-center" style="height: 80px;">
                            <div class="progress progress-bar-vertical" style="background-color: #ffffff;">
                              <div class="progress-bar bg-realizado" role="progressbar" :aria-valuenow="item.porcentualTon" aria-valuemin="0" aria-valuemax="100" :style="'height: '+item.porcentualTon+'%;'">
                                <span class="sr-only">{{ item.porcentualTon }}% Complete</span>
                              </div>
                            </div>
                          </div>
                          <div style="margin-top:5px; font-size: 0.54rem;"><b>{{ valuemoney(item.Ton) }}</b></div>
                          <div style="width: 100%;background: #737576;color:#FFF;padding:2px;text-align: center;font-size:0.6rem;margin-top:5px;border-radius: 10px;">
                            <b>{{ item.VesselHoldName }}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div style="background: #E9F0FA;padding:15px;border-radius: 5px;">
                      <h6 style="font-size: 0.7rem;font-weight: bold;">{{ $t('label.GamelaByWinery') }} TON / 24 {{ $t('label.hour')+'S' }}</h6>
                      <div class="row" style="height: 130px;">
                        <div class="col" style="text-align:center;padding-right: 8px;padding-left: 8px;" v-for="(item, index) in DataTonByHold24Hrs" :key="index">
                          <div class="w-100 d-flex justify-content-center" style="height: 80px;">
                            <div class="progress progress-bar-vertical" style="background-color: #ffffff;">
                              <div class="progress-bar bg-carga-operativa" role="progressbar" :aria-valuenow="item.porcentualCant" aria-valuemin="0" aria-valuemax="100" :style="'height: '+item.porcentualCant+'%;'">
                                <span class="sr-only">{{ item.porcentualCant }}% Complete</span>
                              </div>
                            </div>
                          </div>
                          <div style="margin-top:5px; font-size: 0.54rem;"><b>{{ item.Cant }}</b></div>
                          <div style="width: 100%;background: #737576;color:#FFF;padding:2px;text-align: center;font-size:0.6rem;margin-top:5px;border-radius: 10px;">
                            <b>{{ item.VesselHoldName }}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" style="margin-top:10px;min-height: 165px;">
                    <div style="background: #E2E2E2;padding:15px;border-radius: 5px;min-height: 165px;">
                      <h6 style="font-size: 0.7rem;font-weight: bold;">{{ $t('label.TonEmbargoedByShifts') }}</h6>
                      <div class="row">
                        <div class="col-sm-2 px-1" v-for="(item, index) in DataTonByLapse" :key="index" :style="(index+1 !== DataTonByLapse.length) ? 'box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.2);' : ''">
                          <div class="d-flex" v-for="(item2) in item" :key="item2.NRO" style="font-size: 0.6rem">
                            <div class="flex-fill">
                              {{ item2.LapseCode }}
                            </div>
                            <div class="flex-fill text-right" style="font-weight: bold;">
                              {{ valuemoney(item2.TonWeight) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import { DateFormater } from '@/_helpers/funciones';

    //methods
    function calcPercent(value, total) {
      if(value == 0 || total == 0) return 0;

      let percent = (value/total) * 100;
      return Math.round(percent * 1e2) / 1e2;
    }
    function valuemoney(value){
      if(value !== null){
        let newValue = parseFloat(value).toFixed(2);
        var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
        return new Intl.NumberFormat("de-DE",options).format(newValue);
      }
      return "0,00";
    }
    //computed
    function operationInfo(){
      let DataVessel = this.VesselSituationJson;
      let DataAtaEtd = this.Vessel;
      if(Object.keys(DataVessel).length !== 0 && Object.keys(DataAtaEtd).length !== 0){
        return {
          ata: DataAtaEtd.Ata ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Ata) : 'N/A',
          etd: DataAtaEtd.Etd ? DateFormater.formatDateTimeWithSlash(DataAtaEtd.Etd) : 'N/A',
          ini: DataVessel.BeginShipmentDate ? DateFormater.formatDateTimeWithSlash(DataVessel.BeginOpsDate) : 'N/A',
          end: DataVessel.EndOpsDate ? DateFormater.formatDateTimeWithSlash(DataVessel.EndOpsDate) : 'N/A',
          total: DataVessel.HoursOps ? DataVessel.HoursOps : 'N/A',
          totalProm: DataVessel.TonPerImplement ? DataVessel.TonPerImplement : 0,
        };
      }else{
        return {
          ata:'N/A',
          etd:'N/A',
          ini:'N/A',
          end:'N/A',
          total:'N/A',
          totalProm:'N/A',
        };
      }
    }
    function operationGamelas(){
      let DataVessel = this.VesselSituationJson;
      if(Object.keys(DataVessel).length !== 0){
        DataVessel.LastReadDateModificada = DataVessel.LastReadDate ? DateFormater.formatDateTimeWithSlash(DataVessel.LastReadDate) : 'N/A';
        return DataVessel;
      }else{
        return {
          "ImplementEmbarked": "N/A",
          "ImplementReturned": "N/A",
          "ImplementDispatched": "N/A",
          "VehiclesInCircuit": "N/A",

          "TotalVoyages": "N/A",
          "TonPlanif": "N/A",
          "TonRemaining": "N/A",
          "TonPromXImplement": "N/A",
          "LastRead": "N/A",
          "LastReadDate": "N/A",
        }
      }
    }
    function EmbarkedCargo(){
      let Data = this.HoldSituationJson?.EmbarkedCargoJson;
      if(Data&&Data.length !== 0){
        return Data[0];
      }else{
        return{
          DayTon: 0,
          NightTon: 0,
          TotalTon: 0,
        }
      }
    }
    function Movements(){
      let Data = this.HoldSituationJson?.MovementsJson;
      if(Data&&Data.length !== 0){
        return Data[0];
      }else{
        return{
          DayMov: 0,
          NightMov: 0,
          TotalMov: 0,
        }
      }
    }
    function DataTonByHold24Hrs(){
      let data = [];
      let myDataTonByHold24Hrs = this.HoldSituationJson?.TonByHold24HrsJson ? this.HoldSituationJson?.TonByHold24HrsJson : [];
      if(myDataTonByHold24Hrs.length > 0){
        let cantidadTotal = 0;
        let tonTotal = 0;
        myDataTonByHold24Hrs.map(async (item) => {
          if(item.Cant > 0){
            cantidadTotal = item.Cant + cantidadTotal;
          }
          if(item.Ton > 0){
            tonTotal = item.Ton + tonTotal;
          }
        });
        myDataTonByHold24Hrs.map(async (item) => {
          let exce = item.Cant;
          exce = (cantidadTotal == 0 && item.Cant != 0) ? 100 : exce;
          exce = (cantidadTotal > 0 && item.Cant != 0 && cantidadTotal < item.Cant) ? 100 : exce;

          let exceTon = item.Ton;
          exceTon = (tonTotal == 0 && item.Ton != 0) ? 100 : exceTon;
          exceTon = (tonTotal > 0 && item.Ton != 0 && tonTotal < item.Ton) ? 100 : exceTon;

          let porcentualCant = exce;
          if(cantidadTotal > 0 && item.Cant != 0 && cantidadTotal > item.Cant){
            porcentualCant = this.calcPercent(exce, cantidadTotal);
          }

          let porcentualTon = exceTon;
          
          if(tonTotal > 0 && item.Ton != 0 && tonTotal > item.Ton){
            porcentualTon = this.calcPercent(exceTon, tonTotal);
          }
          
          data.push({
            VesselHoldId: item.VesselHoldId,
            VesselHoldName: item.VesselHoldName,
            porcentualCant: porcentualCant,
            porcentualTon: porcentualTon,
            Cant: item.Cant,
            Ton: item.Ton
          })
        });
      }
      return data;
    }
    function ArrayInGroups(arr,size){
      var chunk = [], i;
      for (i = 0;i<=arr.length;i+=size){
        chunk.push(arr.slice(i,i+size));
      }
      return chunk;
    }
    function DataTonByLapse(){
      let data = [];
      let myDataTonByLapse = this.HoldSituationJson?.TonByLapseJson;
      if(myDataTonByLapse != null){
        if(myDataTonByLapse.length > 0){
          let tercio = ~~(myDataTonByLapse.length / 6);
          let arrayNew = this.ArrayInGroups(myDataTonByLapse,tercio+1);
          return arrayNew;
        }
      } 
      return data;
    }
    export default {
      name:'SummaryChatarra',
      props:{
        HoldSituationJson:{
          type:Object,
          default: function () {
            return {};
          },
        },
        VesselSituationJson:{
          type:Object,
          default: function () {
            return {};
          },
        },
        Vessel:{
          type:Object,
          default: function () {
            return {};
          },
        },
      },
      methods:{
        calcPercent,
        ArrayInGroups,
        valuemoney,
      },
      computed:{
        operationInfo,
        operationGamelas,
        EmbarkedCargo,
        Movements,
        DataTonByHold24Hrs,
        DataTonByLapse,
      }
    }
</script>

<style lang="scss">
  .Space-Evenly{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .estandar-letra{
    font-size: 15px !important;
    font-weight: 100 !important;
  }
  .contenedorLetra {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #00343F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:0px auto;
    padding:3%;

    h2 {
      font-family: sans-serif;
      color: white;
      font-size: 0.7rem;
      font-weight: bold;
      margin-bottom:0px;
    }
  }
  .progress-bar-vertical {
    width: 20px;
    min-height: 100%;
    float: left;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    align-items: center;
    -webkit-align-items: flex-end; /* Safari 7.0+ */
  }

  .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
  }

  .contenedorImagen3{
        background-image: url('/situacionOperativa/chatarra/fondo3_.png');
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
        border-radius: 0px 5px 5px 0px;
        .centrado{
            color:#fff;
            padding-left:10px;
            font-size: 20px !important;
        }
    }
</style>